import { computed, reactive, toRefs, getCurrentInstance, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'

const INIT_PAGE = 0
const INIT_LIMIT = 20
const INIT_TOTAL_CNT = 0

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const route = useRoute()
  const router = useRouter()
  const { getters, commit } = useStore()
  const state = reactive({
    userData: computed(() => getters['user/getData']),
    page: INIT_PAGE,
    limit: INIT_LIMIT,
    totalCnt: INIT_TOTAL_CNT,
    list: [],
    wizzTalk: proxy.$ConstCode.BOOLEAN_VALUE.FALSE,
    initCnt: 0,
    searchWord: route.query.search || '',
    productType: route.query.type || '',
    marketNo: route.query.marketNo || '',
    params: computed({
      get: () => {
        return {
          startNum: state.list.length + 1,
          endNum: (state.page + 1) * state.limit,
          searchWord: state.searchWord,
          productType: state.productType,
          marketNo: state.marketNo
        }
      }
    }),
    listScrollTop: 0,
    categoryNames: proxy.$_.keyBy(proxy.$ConstCode.CATEGORY2_VALUE, 'id')
  })
  const fnListFunction = async () => {
    const res = await proxy.$MyPageSvc.postChatList(state.params)
    if (res.resultCode === '0000') {
      state.page++
      state.totalCnt = res.totalCnt
      state.list = state.list.concat(res.list)
    }
    state.wizzTalk = Number(res.wizzTalk)
  }
  const fnSearch = () => {
    router.push({
      name: 'my-page-wizz-talk-index',
      query: {
        ...route.query,
        search: state.searchWord
      }
    })
  }
  const fnView = item => {
    if (item.productType === 'PARTY') {
      router.push({
        name: 'wizz-talk-index',
        query: {
          partyNo: item.productNo,
          chatroomId: item.chatroomId,
          type: item.type
        }
      })
    } else if (item.productType === 'MARKET') {
      router.push({
        name: 'market-wizz-talk-index',
        query: {
          marketNo: item.productNo,
          memberSeq: item.memberSeq,
          chatroomId: item.chatroomId,
          type: item.type
        }
      })
    }
  }
  const fnDelete = async item => {
    if (!confirm('삭제하시겠습니까?')) return
    const params = {
      chatroomId: item.chatroomId,
      productType: item.productType
    }
    const res = await proxy.$MyPageSvc.postChatDelete(params)
    if (res.resultCode === '0000') {
      alert('리스트에서 메시지를 삭제합니다.')
    } else {
      alert(res.resultMsg)
    }
    fnSearch()
  }
  const fnSetting = async () => {
    const params = {}
    params.wizzTalk = state.wizzTalk
    const res = await proxy.$MyPageSvc.postChatNotificationSetting(params)
    if (res.resultCode === '0000') {
      const data = { ...state.userData }
      data.wizztalk = state.wizzTalk
      commit('user/setData', { data })
    } else {
      alert(res.resultMsg)
    }
  }
  const inputSearchWord = e => {
    state.searchWord = e.target.value
  }

  watch(
    () => state.productType,
    () => {
      router.push({
        name: 'my-page-wizz-talk-index',
        query: {
          ...route.query,
          type: state.productType
        }
      })
    }
  )

  return {
    ...toRefs(state),
    fnListFunction,
    fnSearch,
    fnView,
    fnDelete,
    fnSetting,
    inputSearchWord
  }
}
