<template>
  <main class="main" role="main">
    <div class="page bg">
      <div class="inner">
        <div class="page_con">
          <div class="lump bo_zero">
            <label class="input srh_type02">
              <input
                type="search"
                placeholder="검색어를 입력해 주세요."
                :value="searchWord"
                @input="inputSearchWord"
                @keyup.enter="fnSearch"
              />
              <button
                v-if="searchWord"
                class="btn_search delete"
                @click="() => (searchWord = '')"
              ></button>
              <button class="btn_search" @click="fnSearch">검색</button>
            </label>
          </div>
          <div class="party_list_wrap">
            <div class="list_top">
              <div class="left">
                <button class="total">총 {{ totalCnt }}건</button>
              </div>
              <div class="right">
                <label class="select" style="padding-right: 1.6rem;">
                  <select v-model="productType">
                    <option :value="''">전체</option>
                    <option :value="'PARTY'">구독</option>
                    <option :value="'MARKET'">마켓</option>
                  </select>
                </label>
                <span class="toggle_ttl">알림</span>
                <label class="toggle_type01">
                  <input
                    type="checkbox"
                    :true-value="$ConstCode.BOOLEAN_VALUE.TRUE"
                    :false-value="$ConstCode.BOOLEAN_VALUE.FALSE"
                    v-model="wizzTalk"
                    :checked="$ConstCode.BOOLEAN_VALUE.TRUE"
                    @change="fnSetting"
                  />
                  <span class="circle"></span>
                </label>
              </div>
            </div>
            <div class="party_list row_type" v-if="!$Util.isEmpty(list)">
              <!--'가로형: row_type'-->
              <ul class="party_list_ul">
                <li
                  class="party_list_li"
                  style="height: 13.2rem;"
                  v-for="(item, index) in list"
                  :key="index"
                  @click="fnView(item)"
                >
                  <div class="party_tag">
                    <div class="left">
                      <div
                        class="pt_tag logo"
                        style="--pt-txt-color: #fff;"
                        :style="{
                          '--pt-bg-color': $ConstCode.getCategoryById(
                            item.categoryId
                          ).color
                        }"
                      >
                        {{ categoryNames[item.categoryId].text }}
                      </div>
                      <div class="pt_tag normal">{{ item.nick }}</div>
                    </div>
                    <div class="right">
                      <div
                        class="pt_tag"
                        :class="{ new: item.readYn === 'N' }"
                      ></div>
                    </div>
                  </div>
                  <div class="party_ttl">{{ item.title }}</div>
                  <div class="party_con">
                    <div class="left">
                      <div class="last_chat_txt">
                        {{ item.message }}
                      </div>
                      <div class="last_chat_date">
                        {{ $dayjs(item.regDt).format('YYYY.MM.DD HH:mm:ss') }}
                      </div>
                    </div>
                    <div class="right">
                      <button
                        class="btn line"
                        @click.stop.prevent="fnDelete(item)"
                      >
                        삭제
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="empty_wrap" v-else>
              <div class="empty">
                <div>
                  검색 결과가 없습니다.
                </div>
              </div>
            </div>
            <observe-visible
              :key="initCnt"
              :page="page"
              :limit="limit"
              :totalCnt="totalCnt"
              @fetch="fnListFunction"
            />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'my-page-wizz-talk-index',
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
